




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'BasePictureWithText',
})
export default class BasePictureWithText extends Vue {
  @Prop({ type: String, default: 'preview' }) readonly type!:
    | 'preview'
    | 'success'
    | 'danger'

  @Prop({ type: String }) readonly label!: string

  get defaultText () {
    if (this.type === 'preview') { return this.$t('auth.twoFactorAuth.previewStepText') }

    if (this.type === 'success') { return this.$t('auth.twoFactorAuth.defaultText') }

    return this.$t('auth.twoFactorAuth.authDisableWarnText')
  }

  get imgClass () {
    return this.type === 'preview' ? 'preview-img' : 'default-img'
  }

  get text () {
    return this.label || this.defaultText
  }
}
